/* HomePage.css */

.HomePage {
  background-image: url('/images/background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home-content {
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.home-content h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.home-content p {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.home-nav {
  margin-top: 20px;
}

.home-link {
  margin: 0 10px;
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.home-link:hover {
  text-decoration: underline;
}
