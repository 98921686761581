/* ComicViewer.css */

.ComicViewer {
  min-height: 100vh; /* Cover the entire viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  /* Add a background image or color */
  background-image: url('/images/background.jpg'); /* Update this path as needed */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  /* Optional: Use a background color */
  /* background-color: #f0f0f0; */
}

.ComicViewer h1 {
  margin-bottom: 20px;
  color: #333; /* Ensure visibility against the background */
}

.ComicViewer button {
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.ComicViewer button:hover {
  background-color: #555;
}
